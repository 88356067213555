
import AddToCart from '~/components/product/AddToCart.vue'
export default {
  name: 'ImageContentSliderSlice',
  props: ['slice'],
  components: { AddToCart },
  data() {
    return {
      swiper: null,
      activeIndex: 0,
      nextActiveIndex: 1,
      touchstartX: 0,
      touchendX: 0,
    }
  },
  computed: {
    slides(){
      return this.slice.items
    },
    hasMoreSlides() {
      return this.slides.length > 1
    }
  },
  mounted() {
    // setTimeout(()=>{
      if (this.hasMoreSlides) {
        // const slider = document.querySelector(`#${this.slice.primary.id}`)
        const slider = this.$refs[`${this.slice.primary.id}`]
        if (slider) {
          slider.addEventListener('touchstart', e => {
            this.touchstartX = e.changedTouches[0].screenX
          }, { passive: true })
          slider.addEventListener('touchend', e => {
            this.touchendX = e.changedTouches[0].screenX
            this.checkDirection()
          }, { passive: true })
        }
      }
    // }, 1000)
  },
  // destroyed(){
  //   const slider = document.querySelector(`#${this.sliderId}`)
  //   slider.removeEventListener('touchstart')
  //   slider.removeEventListener('touchend')
  // },
  methods: {
    checkDirection() {
      const threshold = 50
      if (this.touchendX < this.touchstartX - threshold) this.slide('+')
      if (this.touchendX > this.touchstartX + threshold) this.slide('-')
    },
    slide(direction) {
      if (direction === '-') {
        this.activeIndex > 0 ? this.activeIndex = this.activeIndex - 1 : this.activeIndex = this.slides.length - 1
        this.activeIndex === 0 ? this.nextActiveIndex = this.slides.length - 1 : this.nextActiveIndex = this.activeIndex - 1
      } else {
        this.activeIndex < this.slides.length - 1 ? this.activeIndex++ : this.activeIndex = 0
        this.activeIndex === this.slides.length - 1 ? this.nextActiveIndex = 0 : this.nextActiveIndex = this.activeIndex + 1
      }
    }
  }
}
